<template>
  <div class="container-fluid">
    <div class="card nw-card mt-2">
      <div class="card-header nw-card-header text-left">
        <h4>Admin Console User Group - {{ group.groupName}}</h4>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link text-dark" :class="activePage=='profile' ? 'active' : ''" @click="activePage='profile'" href="#">Profile</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-dark" :class="activePage=='users' ? 'active' : ''" @click="userTab" href="#">Users</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-dark" :class="activePage=='patients' ? 'active' : ''"  @click="patientTab" href="#">Patients</a>
          </li>
        </ul>

        <!--
          Profile Page
        -->
        <user-group v-show="activePage=='profile'" :group="group" class="mt-3"></user-group>

        <!--
            Users
        -->
        <div v-show="activePage=='users'" class="text-left mt-3">
          <div v-show="userSearch==false">
            <div class="row">
              <div class="col-6 text-left">
                <button class="btn nw-btn-outline-info" @click="userSearch=true"><i class="fa fa-plus"></i> Add User</button>
              </div>
              <div class="col-6 text-right">
                <button class="btn nw-btn-outline-danger-sm" @click="removeUser"><i class="fa fa-ban"></i> Remove Selected</button>
              </div>
            </div>
            <table class="table table-hover mt-4">
              <thead><tr><th>First Name</th><th>Last Name</th><th>User ID</th><th>Email</th><th>Remove</th></tr></thead>
              <tbody>
                <tr v-for="item in uarr">
                  <td>{{ item.firstName}}</td>
                  <td>{{ item.lastName}}</td>
                  <td>{{ item.userName}}</td>
                  <td>{{ item.email}}</td>
                  <td>
                    <input class="form-check-input ml-4" type="checkbox" v-model="item.remove">
                  </td>
                </tr>
              </tbody>

            </table>
          </div>

          <Errors :retval="retval" :resp="resp"></Errors>
          <user-search v-show="userSearch==true" showCancel="true" @cancel="cancelUser()" @select="selectUser"></user-search>

        </div>


        <!--
            Patients
        -->
        <div v-show="activePage=='patients'" class="text-left mt-3">
          <div v-show="patientSearch==false">
            <div class="row">
              <div class="col-6 text-left">
                <button class="btn nw-btn-outline-info" @click="patientSearch=true"><i class="fa fa-plus"></i> Add Patient</button>
              </div>
              <div class="col-6 text-right">
                <button class="btn nw-btn-outline-danger-sm" @click="removePatient"><i class="fa fa-ban"></i> Remove Selected</button>
              </div>
            </div>
            <table class="table table-hover mt-4">
              <thead><tr><th>Patient ID</th><th>Name</th><th>Remove</th></tr></thead>
              <tbody>
                <tr v-for="item in parr">
                  <td>{{ item.participantID}}</td>
                  <td>{{ item.name}}</td>
                  <td>
                    <input class="form-check-input ml-4" type="checkbox" v-model="item.remove">
                  </td>
                </tr>
              </tbody>

            </table>
          </div>

          <patient-search v-show="patientSearch==true" showCancel="true" @cancel="patientSearch=false" @select="selectPatient"></patient-search>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import NetClient from '@/util/netclient.js'
import UserGroup from '@/components/UserGroup'
import UserSearch from '@/components/UserSearch'
import PatientSearch from '@/components/PatientSearch'
import Errors from '@/components/Errors'

export default {
  components: {UserGroup, UserSearch,PatientSearch, Errors },
  mounted: function() {
    NetClient.doRequest("rest/user", { action: 'group', group: { oid: this.$route.params.oid}}).then(msg => {
      if(typeof msg.data != 'undefined') this.group = msg.data
    })


  },
  watch : {
    '$route.params.oid' : function(n,o)
    {
      console.log('new oid' + n)
    }
  },
  data () {
    return {
      resp: [],
      retval: 'OK',
      activePage: 'profile',
      userSearch: false,
      patientSearch: false,
      uarr: [],
      parr: [],
      group: {
        groupName : 'Some DME Vendor',
        groupType: 0
      }
    }
  },
  methods: {
    cancelUser () {
        this.resp = []
        this.retval = 'OK'
        this.userSearch = false
    },
    userTab () {
      NetClient.doRequest("rest/user", { action: 'groupUsers', groupID: this.$route.params.oid}).then(msg => {
        if(typeof msg.data != 'undefined') this.uarr = msg.data
      })
      this.activePage='users'
    },
    patientTab () {
      NetClient.doRequest("rest/patient", { action: 'groupPatients', groupID: this.$route.params.oid}).then(msg => {
        if(typeof msg.data != 'undefined') this.parr = msg.data
      })
      this.activePage='patients'
    },
    removeUser : function() {
        var ii
        var remove = []
        for(ii=0;ii<this.uarr.length;ii++)
        {
          if(typeof this.uarr[ii].remove != 'undefined' && this.uarr[ii].remove == true) remove.push(this.uarr[ii].oid)
        }

        if(remove.length > 0)
        {
          NetClient.doRequest("rest/user", { action: 'groupUsers', groupID: this.$route.params.oid, remove: remove}).then(msg => {
            if(typeof msg.data != 'undefined') this.uarr = msg.data
          })
          this.userSearch = false
        }
    },
    selectUser (_user) {
      this.retval = 'OK'
      this.resp = []
      NetClient.doRequest("rest/user", { action: 'groupUsers', groupID: this.$route.params.oid, addID: _user.oid}).then(msg => {
        if(typeof msg.data != 'undefined') this.uarr = msg.data
        if('OK' == msg.retval)  this.userSearch = false
        if(typeof msg.retval != 'undefined') this.retval = msg.retval
        if(typeof msg.resp != 'undefined') this.resp = msg.resp
      })

    },
    removePatient : function() {
        var ii
        var remove = []
        for(ii=0;ii<this.parr.length;ii++)
        {
          if(typeof this.parr[ii].remove != 'undefined' && this.parr[ii].remove == true) remove.push(this.parr[ii].oid)
        }

        if(remove.length > 0)
        {
          NetClient.doRequest("rest/patient", { action: 'groupPatients', groupID: this.$route.params.oid, remove: remove}).then(msg => {
            if(typeof msg.data != 'undefined') this.parr = msg.data
          })
          this.patientSearch = false
        }
    },
    selectPatient (_patient) {
      NetClient.doRequest("rest/patient", { action: 'groupPatients', groupID: this.$route.params.oid, addID: _patient}).then(msg => {
        if(typeof msg.data != 'undefined') this.parr = msg.data
      })
      this.patientSearch = false
    }
  }
}

</script>
