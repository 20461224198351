<template>
  <div class="container-fluid text-left mt-3">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Patient Search</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">Patient ID</label>
              <input class="form-control" type="text" v-model="participantID"/>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">First Name</label>
              <input class="form-control" type="text" v-model="firstName"/>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">Last Name</label>
              <input class="form-control" type="text" v-model="lastName"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">Date of Birth</label>
              <input class="form-control" type="text" v-model="dob"/>
            </div>
          </div>
          <div class="col-xs-12 col-sm-8">
            <div class="form-group">
              <label class="control-label">Email</label>
              <input class="form-control" type="text" v-model="email"/>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button class="btn nw-btn-outline-info mr-3" @click="cancel()" v-show="showCancel=='true'">Cancel</button>
          <button class="btn nw-btn-outline-info" @click="search()"><i class="fa fa-search"></i> Search</button>
        </div>
        <errors :resp="nc.resp" :retval="nc.retval" class="mt-2"></errors>
        <div class="row mt-2">
          <div class="col-6 text-left">
            <button class="btn btn-outline-secondary" @click="back()"><i class="fa fa-chevron-left"></i></button>
          </div>
          <div class="col-6 text-right">
            {{ skip }}
            <button class="btn btn-outline-secondary" @click="next()"><i class="fa fa-chevron-right"></i></button>
          </div>
        </div>

        <table class="table table-hover table-sm mt-2">
          <thead><tr><th>Patient ID</th><th>Name</th><th>Date of Birth</th><th>email</th></tr></thead>
          <tbody style="cursor:pointer">
            <tr v-for="item in arr" @click="select(item.oid)">
              <td>{{ item.participantID}}</td>
              <td>{{ item.firstName}} {{ item.lastName}}</span></td>
              <td>{{ item.dob}}</td>
              <td>{{ item.email}}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>



<script>
import NetClient from '@/util/netclient.js'
import Errors from '@/components/Errors'

export default {
  components: { Errors },
  props: ['showCancel'],
  mounted () {
      this.search()
  },
  data () {
    return {
      participantID: '',
      oldPid: '',
      skip: 0,
      limit: 10,
      nc: NetClient,
      arr: []
    }
  },
  methods: {
    select (_oid) {
      this.$emit('select',_oid)
    },
    search () {
      if(this.oldPid != this.participantID)
      {
        this.skip = 0
        this.oldPid = this.participantID
      }

      var req = {
        action: 'searchPatient',
        skip: this.skip,
        limit: this.limit,
        participantID: this.participantID,
        lastName: this.lastName,
        firstName: this.firstName,
        dob: this.dob,
        email: this.email
      }

      NetClient.doRequest('rest/patient', req).then(msg => {
        if(typeof msg.data != 'undefined') this.arr = msg.data
        else this.arr = []
      })
    },
    cancel () {
      this.$emit('cancel')
    },
    next () {
      this.skip = this.skip + this.limit
      this.search()
    },
    back () {
      this.skip = this.skip - this.limit
      if(this.skip < 0) this.skip = 0
      this.search()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
